@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'animate.css';

.redes_sociales{

    height:calc(6vh + 0.1vmin);
    border-radius: 50%;
    padding: 1%;
    
}


/* .redes_sociales:hover{
    height:calc(5vh + 1.3vmin);
} */

.redes_sociales:hover {
    animation-name: g;
    animation-duration: 0.5s;
}

@keyframes g {
    0% {
        transform: scale(1) 
    }
    40%{
        transform: scale(0.5)
    }
    60%{
        transform: scale(1.5)
    }
    100%{
        transform: scale(1)
    }
}