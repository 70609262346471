@import 'bootstrap/dist/css/bootstrap.min.css';

.presentation_mensage{
  
    color: rgb(251, 245, 245);
    font-weight: 300;

}

.columna1_presentacion{
    background-color: rgba(7, 7, 7, 0.8);
    /* height: calc(35vh + 1vmin); */
    font-size: calc(10px + 1vmin);
    text-align: right;
}

.columna2_presentacion{
  
    display: flex;
    align-items:center;
    justify-content: center;
    color: rgb(251, 252, 251);
    text-align: center;
    font-size: calc(4vh + 2vmin);
    height: calc(35vh + 2vmin);

    animation-duration: 2s;
    animation-name: slidein;
    animation-iteration-count:initial;  
}

@keyframes slidein {
    from {
      margin-left: 100%;
      
    }
  
    to {
      margin-left: 0%;
      
    }
  }
  

