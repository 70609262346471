@import 'bootstrap/dist/css/bootstrap.min.css';

.menu {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.columna1_menu {
    color: black;
    background-color:aliceblue;
    h1{
        font-weight: 900;    
        font-size: calc(15vh + 1vmin);
        text-shadow: calc(0.2vh + 1vmin) calc(0.1vh + 0.1vmin) calc(0.1vh + 1vmin) rgb(104, 104, 102);
    }
}

.columna2_menu {
    height: calc(30vh + 1vmin);
    display: flex;
    align-items: center;
    h2{
        font-size: calc(2.5vh + 1vmin);
    font-family: Oswald;
    font-weight: 400;
    color: aliceblue;
    text-decoration-line:underline;
    font-style: italic;
    text-shadow: calc(0.2vh + 1vmin) calc(0.1vh + 0.1vmin) calc(0.1vh + 1vmin) rgb(104, 104, 102);
    }
    
}