@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Sometype+Mono&display=swap');


body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image:linear-gradient(rgba(224, 241, 31, 0.1)calc(25% + 6vmin),rgb(15, 15, 14,0.7)calc(42% + 6vmin)),url('../img/medellin1.jpg');
  background-repeat: no-repeat;
  background-position-y: calc(80vh * 2vmin); 
  background-size: calc(100% + 1vmin);
  height: calc(150vh + 6vmin);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


