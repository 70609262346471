@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono&display=swap');

.app {
  /* font-family: 'Sometype Mono', monospace; */
  font-family: Oswald;
  position: relative; 
  
}




